import React from "react"
import { Link } from "gatsby"
import { Icon, InlineIcon } from "@iconify/react"
import bxListPlus from "@iconify/icons-bx/bx-list-plus"
import fridgeIcon from "@iconify/icons-mdi/fridge"
import bookIcon from "@iconify/icons-dashicons/book"
import feedbackLine from "@iconify/icons-ri/feedback-line"

const Nav = ({ siteTitle }) => {
  return (
    <nav>
      <ul className="flex m-0 w-full">
        <li className="m-auto">
          <Link
            to="/search-ingredients/"
            className="inline-block rounded hover:border-gray-200 text-blue-500 hover:bg-gray-200 py-1 px-3"
          >
            <Icon
              icon={bxListPlus}
              style={{ color: "#408AF8", fontSize: "30px" }}
            />
          </Link>
        </li>
        <li className="m-auto">
          <Link
            to="/my-fridge/"
            className="inline-block rounded hover:border-gray-200 text-blue-500 hover:bg-gray-200 py-1 px-3"
          >
            <Icon
              icon={fridgeIcon}
              style={{ color: "#408AF8", fontSize: "30px" }}
            />
          </Link>
        </li>
        <li className="m-auto">
          <Link
            to="/recipe-suggestions/"
            className="inline-block rounded hover:border-gray-200 text-blue-500 hover:bg-gray-200 py-1 px-3"
          >
            <Icon
              icon={bookIcon}
              style={{ color: "#408AF8", fontSize: "30px" }}
            />
          </Link>
        </li>
        <li className="m-auto">
          <Link
            to="/feedback"
            className="inline-block rounded hover:border-gray-200 text-blue-500 hover:bg-gray-200 py-1 px-3"
          >
            <Icon
              icon={feedbackLine}
              style={{ color: "#408AF8", fontSize: "30px" }}
            />
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
